import React, { useEffect } from 'react';

const GoogleLoginButton = () => {
   useEffect(() => {
     /* global google */
     google.accounts.id.initialize({
       client_id: 'YOUR_CLIENT_ID', // ここにGoogle API Consoleで取得したクライアントIDを入力
       callback: handleCredentialResponse
     });
     google.accounts.id.renderButton(
       document.getElementById('buttonDiv'),
       { theme: 'outline', size: 'large' }  // Button customization
     );
   }, []);

   const handleCredentialResponse = (response) => {
     console.log('Encoded JWT ID token: ' + response.credential);
     // JWTトークンを使用してサーバー側でユーザーを認証、または状態を変更します
   };

   return (
     <div id="buttonDiv"></div>
   );
 };

export default GoogleLoginButton;
